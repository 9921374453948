import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
        backgroundColor: '#f5f5f5',
    }
});

const Imagem = (props) => {
    const classes = useStyles();
    const [conteudo] = useState({
        ...props.conteudo
    });

    return (
        <div>
            <CssBaseline />

            <Container className={classes.paper} component={Paper}>
                <img
                    alt=''
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '20px',
                        width: conteudo.imagem.largura ? conteudo.imagem.largura : 300
                    }}
                    src={conteudo.imagem.imagemUrl}
                />
                <Typography
                    style={{
                        backgroundColor: 'white',
                        padding: '10px'

                    }}
                    variant="body2"
                    component="p"
                >
                    {conteudo.imagem.legenda}

                </Typography>
            </Container>

        </div>
    );
}

export default Imagem;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    card: {
        margin: 'auto',
        width: '80%',
    },
    title: {
        fontSize: 25,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function AlunoProfile({ aluno }) {
    const classes = useStyles();

    return (
        <Card className={classes.card} variant="outlined">
            <CardContent>
                <Grid container justifyContent='space-evenly' spacing={3}>
                    <Grid item xs={12} md={6} lg={4} justifyContent='center'>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Código
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {aluno && aluno.codAlunoEscola ? aluno.codAlunoEscola : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} justifyContent='center'>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Nome
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {aluno && aluno.nome ? aluno.nome : ''}
                        </Typography>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    );
}
import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Paper } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import CronogramaForm from './CronogramaForm';
import CronogramaTable from './CronogramaTable';
import TurmaTableSelectRedux from '../Turma/TurmaTableSelectRedux';
import CronogramaProfessorContainer from "./CronogramaProfessor/CronogramaProfessorContainer";
import { errorMessage, successMessage } from "../../util/ConfirmAlertUtil";
import { deleteById, findByTurmaCodigo } from './CronogramaData/api-cronograma-data';
import CronogramaDataTable from "./CronogramaData/CronogramaDataTable";
import CronogramaDataForm from "./CronogramaData/CronogramaDataForm";
import { save as saveCronogramaData } from "./CronogramaData/api-cronograma-data";
import CronogramaDataProfile from "./CronogramaData/CronogramaDataProfile";
import { confirmAlert } from "react-confirm-alert";
import TurmaProfile from "../Turma/TurmaProfile";
import { setTurma } from '../Turma/actions/actions';
import { toast } from 'react-toastify';
import CronogramaTableSelect from "./CronogramaTableSelect";
import { saveAll } from "./api-cronograma";

const CronogramaContainer = () => {
    const turma = useSelector(state => state.turma);
    const [listCronogramaData, setListCronogramaData] = useState([]);
    const [isLoadingCronogramaData, setIsLoadingCronogramaData] = useState(true);
    const [cronogramaData, setCronogramaData] = useState(null);
    const [cronogramaDataSelected, setCronogramaDataSelected] = useState(null);
    const cronograma = useSelector(state => state.cronograma);
    const [menu, setMenu] = useState(0);
    const [loadingSave, setLoadingSave] = useState(false);
    const dispatch = useDispatch();
    const [openCronogramaTableSelect, setOpenCronogramaTableSelect] = useState(false);
    const [listCronogramaSelected, setListCronogramaSelected] = useState([]);
    const [cronogramaDataAnterior, setCronogramaDataAnterior] = useState([]);

    useEffect(() => {

        if (turma) {
            setIsLoadingCronogramaData(true);
            findByTurmaCodigo(turma.codigo).then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setListCronogramaData(data);
                    }
                    setIsLoadingCronogramaData(false);
                }
            });
        }
    }, [turma]);

    const handleAdicionarCronogramaData = () => {
        setCronogramaData((prevState) => ({
            ...prevState,
            eEdit: true,
            eNovo: true
        }));
    }

    const handleDelete = (codigo) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este cronograma data?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        deleteById(codigo).then((data) => {
                            if (data) {
                                if (data.error) {
                                    errorMessage(data.message);
                                } else {
                                    successMessage("Cronograma Data Removido com sucesso.");
                                    setListCronogramaData((prevState) => prevState.filter((ps) => ps.codigo !== codigo));
                                }
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });


    }

    const handleSave = (event) => {
        event.preventDefault();

        if (cronogramaData.dataInicio === null) {
            errorMessage("Data Início não pode ser vazia.");
        }

        const persist = {
            ...cronogramaData,
            turma: turma
        }
        const eNovo = cronogramaData.eNovo
        setLoadingSave(true);
        saveCronogramaData(persist).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setCronogramaData(data);
                    if (eNovo) {
                        setListCronogramaData((prevState) => ([
                            ...prevState,
                            data
                        ]))
                    } else {
                        setListCronogramaData((prevState) => prevState.map((ps) => {
                            if (ps.codigo === data.codigo) {
                                return data;
                            } else {
                                return ps;
                            }
                        }));
                    }
                    toast.success("Salvo com sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });

                }
            }
            setLoadingSave(false);
        })

    }

    const handleTrocaTurma = () => {
        dispatch(setTurma(null));
        setCronogramaData(null);
        setCronogramaDataSelected(null);
    }

    const handleOpenCronogramaTableSelect = () => {
        const index = listCronogramaData.findIndex(cronogramaDataElement => (cronogramaDataElement.codigo === cronogramaDataSelected.codigo));
        if (index > 0) {
            setCronogramaDataAnterior(listCronogramaData[index - 1]);
            setOpenCronogramaTableSelect(true);
        } else {
            errorMessage("Não existe Cronograma Anterior para ser copiado.");
        }

    }

    const handleAdicionarCronogramasSelect = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja Adicionar ' + listCronogramaSelected.length + ' registros?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        const persist = listCronogramaSelected.map((element) => ({
                            ...element,
                            codigo: null,
                            cronogramaData: cronogramaDataSelected
                        }));
                        saveAll(persist).then((data) => {
                            if (data) {
                                if (data.error) {
                                    errorMessage(data.message);
                                } else {
                                    successMessage("Cronogramas adicionados com sucesso. Atualize a página para verificar a inserção.");
                                }
                            }
                        })
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <Container component={Paper}>
            <Grid direction='column' container spacing={3}>
                <Grid item>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Grid item>
                            <Typography variant="h6">
                                Cronograma
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' spacing={3}>
                        <Grid item>
                            <Button onClick={() => setMenu(0)}>Turma</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => setMenu(1)}>Professor</Button>
                        </Grid>
                    </Grid>
                </Grid>

                {menu === 0 ?
                    <>
                        {turma ?
                            <>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <TurmaProfile turma={turma} handleTrocaTurma={handleTrocaTurma} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        {cronogramaDataSelected ?
                                            <CronogramaDataProfile
                                                cronogramaData={cronogramaDataSelected}
                                                setCronogramaData={setCronogramaDataSelected}
                                            /> : ''}
                                    </Grid>
                                </Grid>

                                {cronogramaDataSelected ?
                                    <>
                                        {openCronogramaTableSelect ?
                                            <Grid item>
                                                <CronogramaTableSelect
                                                    cronogramaData={cronogramaDataAnterior}
                                                    setOpenCronogramaTableSelect={setOpenCronogramaTableSelect}
                                                    listCronogramaSelected={listCronogramaSelected}
                                                    setListCronogramaSelected={setListCronogramaSelected}
                                                    handleAdicionarCronogramasSelect={handleAdicionarCronogramasSelect}
                                                />
                                            </Grid> :
                                            <Grid item>
                                                {cronograma ?
                                                    <CronogramaForm
                                                        cronogramaData={cronogramaDataSelected}
                                                    /> :
                                                    <>
                                                        <CronogramaTable cronogramaData={cronogramaDataSelected} handleOpenCronogramaTableSelect={handleOpenCronogramaTableSelect} />
                                                    </>
                                                }
                                            </Grid>
                                        }

                                    </> :
                                    <Grid item>
                                        {cronogramaData ?
                                            <CronogramaDataForm
                                                cronogramaData={cronogramaData}
                                                setCronogramaData={setCronogramaData}
                                                loadingSave={loadingSave}
                                                clickSubmit={handleSave}
                                            /> :
                                            <CronogramaDataTable
                                                listCronogramaData={listCronogramaData}
                                                setCronogramaData={setCronogramaData}
                                                setCronogramaDataSelected={setCronogramaDataSelected}
                                                handleDelete={handleDelete}
                                                handleAdicionarCronogramaData={handleAdicionarCronogramaData}
                                                isLoading={isLoadingCronogramaData}
                                            />
                                        }
                                    </Grid>
                                }
                            </>
                            :
                            <TurmaTableSelectRedux />
                        }
                    </> :
                    <><CronogramaProfessorContainer /></>
                }

            </Grid>
        </Container>
    );
}

export default CronogramaContainer;
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TurmaTableSelectRedux from '../Turma/TurmaTableSelectRedux';
import TurmaProfileRedux from '../Turma/TurmaProfileRedux';
import { findByTurmaCodigo } from '../AlunoResponsavel/api-aluno-responsavel';
import { findByTurmaCodigoAndAtivo } from '../AlunoTurma/api-aluno-turma';
import Loading from '../UI/Loading';
import MensagemFindTurmaAlunoTurmaList from './MensagemFindTurmaAlunoTurmaList';
import { getUsuarioSala } from "./api-mensagem";
import { useHistory } from 'react-router-dom';
import MensagemFindTurmaResponsavelList from './MensagemFindTurmaResponsavelList';
import { errorMessage } from '../../util/ConfirmAlertUtil';

const MensagemFindTurma = () => {
    const turma = useSelector(state => state.turma);
    const [isLoading, setIsLoading] = useState(false);
    const [alunosResponsaveis, setAlunosResponsaveis] = useState([]);
    const [alunosTurmas, setAlunosTurmas] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (turma) {
            setIsLoading(true);
            findByTurmaCodigo(turma.codigo).then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setAlunosResponsaveis(data);
                        setIsLoading(false);
                    }
                }
            });
            findByTurmaCodigoAndAtivo(turma.codigo, true).then((data) => {
                if (data) {
                    if (data.error) {
                        errorMessage(data.message);
                    } else {
                        setAlunosTurmas(data);
                        setIsLoading(false);
                    }
                }
            })
        }

    }, [turma]);

    const handleSelectedUser = (usuario) => {
        getUsuarioSala(usuario.id).then((data) => {
            if (data) {
                history.push('/sala-mensagem', { sala: data })
            }
        })
    }

    return (
        <>
            {turma ?
                <>
                    <TurmaProfileRedux />
                    {isLoading ?
                        <Loading description='Carregando Contatos...' />
                        :

                        <>
                            <MensagemFindTurmaAlunoTurmaList
                                alunosTurmas={alunosTurmas}
                                handleSelectedUser={handleSelectedUser}
                            />
                            <MensagemFindTurmaResponsavelList
                                alunosResponsaveis={alunosResponsaveis}
                                handleSelectedUser={handleSelectedUser}
                            />
                        </>
                    }
                </> :
                <TurmaTableSelectRedux />
            }

        </>
    );
}

export default MensagemFindTurma;
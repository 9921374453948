import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import 'react-toastify/dist/ReactToastify.css';
import { requestResetPassword } from './api-auth.js';
import { findByHostname } from "../components/Instituicao/api-instituicao";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    logo: {
        width: '100%',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    messageSuccess: {
        border: '1px solid darkgreen',
        padding: '30px',
        borderRadius: '30px',
        backgroundColor: 'palegreen',
    },
    messageError: {
        border: '1px solid Red',
        padding: '30px',
        borderRadius: '30px',
        backgroundColor: 'Tomato',
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
}));

export default function RequestResetPassword() {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [instituicao, setInstituicao] = useState('');
    const [message, setMessage] = useState({
        content: '',
        type: ''
    })
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        findByHostname(window.location.href).then((data) => {
            if (data) {
                if (data.error) {
                    console.log(data);
                } else {
                    setInstituicao(data);
                }
            }

        });

    }, []);

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        requestResetPassword(email).then((data) => {
            if (data.error) {
                if (data.message === 'User not found') {
                    setMessage({
                        content: 'Não existe usuário cadastrado com esse e-mail.',
                        type: 'ERROR'
                    });
                } else if (data.message === 'More than 3 attempts') {
                    setMessage({
                        content: 'Já foram feitas 3 tentativas. Verifique o e-mail em sua Caixa de Spam.',
                        type: 'ERROR'
                    });
                }
                console.log(data);
            } else {
                setMessage({
                    content: 'E-mail enviado. Acesse sua conta de e-mail e siga os passos para resetar a sua senha. ' +
                        'O link para resetar a senha fircará ativo por 1 hora, caso a troca não for realizada nesse período o processo poderá ser repetido no próximo dia.',
                    type: 'SUCCESS'
                });
            }
            setIsLoading(false);
        })

    }

    const handleChange = (event) => {
        setEmail(event.target.value)
    }

    return (
        <div>
            <CssBaseline />
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    {instituicao ? <img src={instituicao.urlLogo}
                        className={classes.logo} alt="Logo" /> : ''}

                    <Typography variant="h5">
                        Resetar Senha
                    </Typography>

                    <form onSubmit={clickSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            name="username"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange}
                            value={email}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            className={classes.submit}>
                            {isLoading && <CircularProgress className={classes.loading} color="primary" size={20} />}
                            Enviar E-mail
                        </Button>
                        {message.type === 'SUCCESS' ?
                            <Typography className={classes.messageSuccess} >
                                {message.content}
                            </Typography> :
                            ''
                        }
                        {message.type === 'ERROR' ?
                            <Typography className={classes.messageError} >
                                {message.content}
                            </Typography> :
                            ''
                        }

                    </form>

                </div>
            </Container>
        </div>
    );
}
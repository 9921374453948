import React from "react";
import { useSelector } from "react-redux";
import Configuracao from "./Configuracao";
import UsuarioPerfilTable from "../../Usuario/UsuarioPerfilTable";
import AlunoResponsavelAtivoTable from "../../AlunoResponsavel/AlunoResponsavelAtivoTable";
import UsuarioInstituicaoTable from "../../UsuarioInstituicao/UsuarioInstituicaoTable";
import SystemColors from "./SystemColors";
import { ADMIN } from "../../Usuario/Perfil";
import { Grid, Paper } from "@material-ui/core";
import CredentialK8PayContainer from "../../CredentialK8Pay/CredentialK8PayContainer";

const ConfiguracaoInstituicaoContainer = () => {
  const usuario = useSelector((state) => state.usuario);
  return (
    <Grid container spacing={4} direction="column">
      <Grid item>
        <Configuracao />
      </Grid>
      <Grid item>
        <UsuarioPerfilTable />
      </Grid>
      {usuario.perfil === ADMIN && (
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <CredentialK8PayContainer />
          </Paper>
        </Grid>
      )}
      <Grid item>
        <AlunoResponsavelAtivoTable />
      </Grid>
      <Grid item>
        <UsuarioInstituicaoTable />
      </Grid>
      <Grid item>
        {usuario && usuario.perfil === ADMIN && <SystemColors />}
      </Grid>
    </Grid>
  );
};

export default ConfiguracaoInstituicaoContainer;

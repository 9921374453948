import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AlunoProfile from '../../Aluno/AlunoProfile';
import Container from '../../UI/Container';
import { findByAlunoCodigoAndPendente, findByAlunoCodigoAndAVencer, findByAlunoCodigoAndAnoLetivo } from '../api-lancamento';
import { initMessage, handleFincanceiroError, typeError, typeSuccess } from '../util/exceptionFinanceiro';
import Loading from '../../UI/Loading';
import HistoricoAnoLetivoSelect from './HistoricoAnoLetivoSelect';
import Button from '../../UI/Button';
import LancamentoTableAlunoView from './LancamentoTableAlunoView';
import FinanceiroMenuV2 from './FinanceiroMenuV2';

const useStyles = makeStyles({
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer',
        flexBasis: '5%'
    },
    successView: {
        backgroundColor: '#77cee2',
        marginTop: 10,
        width: '100%'
    },
    errorView: {
        backgroundColor: '#841414',
        marginTop: 10,
        width: '100%'
    },
    textSuccess: {
        padding: 10,
    },
    textError: {
        padding: 10,
        color: 'white',
    },
    textTitle: {
        fontWeight: 'bold',
    },
});

const pendenteKey = 'pendente';
const aVencerKey = 'aVencer';
const historicoKey = 'historico';

export {
    pendenteKey,
    aVencerKey,
    historicoKey
}

const FinanceiroResponsavelContainer = () => {
    const classes = useStyles();
    const usuario = useSelector(state => state.usuario);
    const [lancamentos, setLancamentos] = useState([]);
    const [message, setMessage] = useState(initMessage);
    const [isLoading, setIsLoading] = useState(false);
    const [anoLetivo, setAnoLetivo] = useState(new Date().getFullYear());
    const [isHistorico, setIsHistorico] = useState(false);

    const handleParcelaPendente = () => {
        setIsHistorico(false);
        setIsLoading(true);
        setMessage(initMessage);
        findByAlunoCodigoAndPendente(usuario.arAlunoSelecionado.codigo).then((data) => {
            if (data) {
                if (data.error) {
                    handleFincanceiroError(data, setMessage);

                } else {
                    setLancamentos(data);
                    if (data.length === 0) {
                        setMessage({
                            type: typeSuccess,
                            description: 'Todas as Parcelas estão em dia!'
                        })
                    } else if (data.length > 1) {
                        setMessage({
                            type: typeError,
                            description: 'Constam as seguintes parcelas em aberto.'
                        })

                    } else {
                        setMessage({
                            type: typeError,
                            description: 'Consta a seguinte parcela em aberto.'
                        })
                    }
                }
            }
            setIsLoading(false);
        })
    }

    const handleParcelaVencer = () => {
        setIsHistorico(false);
        setIsLoading(true);
        setMessage(initMessage);
        findByAlunoCodigoAndAVencer(usuario.arAlunoSelecionado.codigo).then((data) => {
            if (data.error) {
                handleFincanceiroError(data, setMessage);
            } else {

                setLancamentos(data);
                if (data.length === 0) {
                    setMessage({
                        type: typeSuccess,
                        description: 'Nenhuma parcela a Vencer!'
                    })
                }
            }
            setIsLoading(false);
        })
    }

    const handleHistorico = () => {
        setIsHistorico(true);
        setIsLoading(true);
        setMessage(initMessage);
        if (anoLetivo && usuario.arAlunoSelecionado.codigo) {
            findByAlunoCodigoAndAnoLetivo(usuario.arAlunoSelecionado.codigo, anoLetivo).then((data) => {
                if (data.error) {
                    handleFincanceiroError(data, setMessage);
                } else {
                    setLancamentos(data);
                    if (data.length === 0) {
                        setMessage({
                            type: typeSuccess,
                            description: 'Nenhuma parcela(s) cadastrada no Ano!'
                        })
                    }
                }
                setIsLoading(false);
            })
        }
    }

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="h6">
                        Financeiro
                    </Typography>
                </Grid>

                {usuario.arAlunoSelecionado &&
                    <>
                        <Grid item xs={12}>
                            <AlunoProfile aluno={usuario.arAlunoSelecionado} />
                        </Grid>
                        <Grid item xs={12}>
                            <FinanceiroMenuV2
                                handleParcelaPendente={handleParcelaPendente}
                                handleParcelaVencer={handleParcelaVencer}
                                handleHistorico={handleHistorico}
                            />
                        </Grid>
                        {isLoading ?
                            <Grid item xs={12} justifyContent='center'>
                                <Loading description="Carregando Lançamentos..." />
                            </Grid>
                            :
                            <>
                                {isHistorico ?
                                    <>
                                        {anoLetivo ?
                                            <Grid item xs={12}>
                                                <Button
                                                    onClick={() => setAnoLetivo(null)}
                                                >
                                                    Trocar Ano Letivo
                                                </Button>
                                            </Grid>
                                            :
                                            <Grid item xs={12}>
                                                <HistoricoAnoLetivoSelect setAnoLetivo={setAnoLetivo} />
                                            </Grid>

                                        }
                                    </> : ''
                                }
                                <Grid item xs={12}>
                                    {message && message.type ?
                                        <div className={message.type === typeSuccess ? classes.successView : classes.errorView}>
                                            <p
                                                className={message.type === typeSuccess ? classes.textSuccess : classes.textError}
                                            >
                                                {message.description}
                                            </p>
                                        </div>
                                        : ''
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {isHistorico ?
                                        <>
                                            {anoLetivo ? <LancamentoTableAlunoView lancamentos={lancamentos} setLancamentos={setLancamentos} /> : ''}
                                        </> :
                                        <LancamentoTableAlunoView lancamentos={lancamentos} setLancamentos={setLancamentos} />
                                    }
                                </Grid>


                            </>

                        }

                    </>}
            </Grid>
        </Container>
    );
}

export default FinanceiroResponsavelContainer;
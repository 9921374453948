import { Container, makeStyles, Paper, TablePagination, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import MuralCard from '../MuralCard';
import { page } from './api-mural-usuario';
import TablePaginationActions from '../../../shared/components/Table/Actions';
import Loading from '../../UI/Loading';

const useStyles = makeStyles({
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        flex: '1 1 100%',
        marginBottom: '20px',
        marginTop: '20px'
    },
})

const MuralUsuarioContainer = () => {
    const classes = useStyles();
    const [pageMuralUsuario, setPageMuralUsuario] = useState({
        content: [],
        size: 0,
        number: 0,
        totalPages: null
    })
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchMuralUsuario(0);
    }, []);

    const handleChangePage = (event, newPage) => {
        fetchMuralUsuario(newPage);
    }

    const fetchMuralUsuario = (newPage) => {
        setIsLoading(true);
        page(newPage).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setPageMuralUsuario(data);
                }
            }
            setIsLoading(false);
        })
    }

    return (
        <>
            {pageMuralUsuario.content.length > 0 ?
                <Container className={classes.paper} component={Paper}>
                    <Typography className={classes.title} variant="h5">
                        Mural de Fotos
                    </Typography>
                    {isLoading ? <Loading description='Carregando Mural de Fotos' /> : <>
                        {pageMuralUsuario.content.map(muralUsuario => (
                            <div key={muralUsuario.codigo}>
                                <MuralCard
                                    mural={muralUsuario.mural}
                                />
                            </div>

                        ))}
                    </>}

                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={pageMuralUsuario.size ? pageMuralUsuario.size : 0}
                        component="div"
                        count={pageMuralUsuario.totalElements}
                        page={pageMuralUsuario.number}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + " de " + (count !== -1 ? count + " registros" : + " mais de " + to))}
                    />
                </Container> : ''
            }

        </>
    )
}

export default MuralUsuarioContainer;
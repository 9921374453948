import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import ConfiguracaoInstituicaoContainer from './Instituicao/ConfiguracaoInstituicaoContainer';
import { useState } from 'react';
import ConfiguracaoInstituicaoProvidenceContainer from './InstituicaoProvidence/ConfiguracaoInstituicaoProvidenceContainer';
import { useSelector } from 'react-redux';
import { ADMIN, COORDENADOR, SECRETARIA } from '../Usuario/Perfil';

const useStyles = makeStyles({
    usuarioPerfilTable: {
        marginTop: '30px'
    },
    container: {
        marginTop: '15px'
    }
});


export default function ConfiguracaoContainer() {
    const classes = useStyles();
    const instituicaoKey = 'instituicao';
    const instituicaoProvidenceKey = 'instituicaoProvidence';
    const [menu, setMenu] = useState(instituicaoKey);
    const usuario = useSelector(state => state.usuario);

    return (
        <Container className={classes.container} component={Paper}>
            <Grid container spacing={3} direction='column'>
                <Grid item>
                    <Typography variant="h5">
                        Configurações
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container spacing={3} direction='row'>
                        <Grid item>
                            <Button onClick={() => setMenu(instituicaoKey)} variant='outlined'>Instituição</Button>
                        </Grid>
                        {usuario.perfil === ADMIN ||
                            usuario.perfil === COORDENADOR ||
                            usuario.perfil === SECRETARIA ?
                            <Grid item>
                                <Button
                                    onClick={() => setMenu(instituicaoProvidenceKey)}
                                    variant='outlined'>
                                    Instituição Providence
                                </Button>
                            </Grid> :
                            ''
                        }

                    </Grid>
                </Grid>
                <Grid item>
                    {menu === instituicaoKey ?
                        <ConfiguracaoInstituicaoContainer /> :
                        <ConfiguracaoInstituicaoProvidenceContainer />
                    }
                </Grid>

            </Grid>



        </Container>
    );
}
import React from "react";
import Button from "../UI/Button";
import { Grid, TextField } from "@material-ui/core";

const CredentialK8PayForm = ({
  credentialK8Pay,
  setCredentialK8Pay,
  handleSave,
}) => {
  const handleOnChange = (event) => {
    setCredentialK8Pay((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          label="ClientId"
          onChange={handleOnChange}
          name="clientId"
          value={
            credentialK8Pay && credentialK8Pay.clientId
              ? credentialK8Pay.clientId
              : ""
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="ClientSecret"
          onChange={handleOnChange}
          name="clientSecret"
          value={
            credentialK8Pay && credentialK8Pay.clientSecret
              ? credentialK8Pay.clientSecret
              : ""
          }
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="ChaveAES"
          onChange={handleOnChange}
          name="chaveAES"
          value={
            credentialK8Pay && credentialK8Pay.chaveAES
              ? credentialK8Pay.chaveAES
              : ""
          }
        />
      </Grid>



      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Button onClick={handleSave} >Salvar</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CredentialK8PayForm;

import React from "react";
import Imagem from "./components/Imagem";
import Text from "./components/Texto";
import ExerEssay from "./components/ExerEssay";
import ExerAlternative from "./components/ExerAlternative";
import Video from "./components/Video";

const Components = {
  image: Imagem,
  text: Text,
  exerEssay: ExerEssay,
  exerAlternative: ExerAlternative,
  video: Video


};

const components = (conteudo, tdp) => {
  if (typeof Components[conteudo.componente] !== "undefined") {
    return React.createElement(Components[conteudo.componente], {
      key: conteudo.codigo,
      conteudo: conteudo,
      tdp: tdp,
    });
  }
  return React.createElement(
    () => <div>The component {conteudo.componente} has not been created yet.</div>,
    { key: conteudo.codigo }
  );
};

export default components;
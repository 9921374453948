import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { findAllPerfis } from './api-usuario';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';
import TextFieldCellPhone from '../UI/TextFieldCellPhone';
import { dateTimeFormat } from '../../util/DateUtil';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import UsuarioPhotoForm from './UsuarioPhotoForm';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

const UsuarioForm = ({ usuario, setUsuario, handleSaveUsuario }) => {
    const classes = useStyles();
    const [perfis, setPerfis] = useState([]);

    useEffect(() => {
        findAllPerfis().then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setPerfis(data);
            }
        });
    }, []);


    const handleChange = (event) => {
        setUsuario({
            ...usuario,
            [event.target.name]: event.target.value ? event.target.value : null
        })
    }

    const handlePerfilChange = (event) => {
        const perfil = perfis.find((perfil) => {
            return perfil === event.target.value;
        });
        setUsuario({
            ...usuario,
            perfil: perfil
        })
    }

    const handleSwitchChange = name => event => {
        setUsuario({ ...usuario, [name]: event.target.checked })
    };



    return (
        <Container component={Paper} maxWidth="sm">
            {!usuario.eNovo ?
                <UsuarioPhotoForm
                    usuario={usuario}
                    setUsuario={setUsuario}
                /> : ''
            }

            <form onSubmit={handleSaveUsuario}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size='small'
                    inputProps={{ maxLength: 255 }}
                    autoFocus
                    label="Nome"
                    name="nome"
                    onChange={handleChange}
                    value={usuario && usuario.nome ? usuario.nome : ''}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    size='small'
                    fullWidth
                    label="Email"
                    inputProps={{ maxLength: 255 }}
                    name="email"
                    autoComplete="email"
                    onChange={handleChange}
                    value={usuario && usuario.email ? usuario.email : ''}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    size='small'
                    fullWidth
                    label="Cpf/Cnpj - Apenas números"
                    inputProps={{ maxLength: 20 }}
                    name="cpfCnpj"
                    onChange={handleChange}
                    value={usuario && usuario.cpfCnpj ? usuario.cpfCnpj : ''}
                />
                <FormControl required fullWidth>
                    <InputLabel id="perfil-label">Perfil Ativo</InputLabel>
                    <Select
                        labelId="perfil-label"
                        id="perfil-select"
                        value={usuario && usuario.perfil ? usuario.perfil : ''}
                        onChange={handlePerfilChange}
                    >
                        {perfis.map((perfil, index) => (
                            <MenuItem key={index} value={perfil}>{perfil}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextFieldCellPhone
                    celular={usuario && usuario.celular ? usuario.celular : ''}
                    handleChange={handleChange}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={usuario && usuario.ativo ? true : false}
                            onChange={handleSwitchChange('ativo')}
                            value={usuario && usuario.ativo ? true : false}
                        />
                    }
                    label="Ativo"
                />
                <p>Data Último Acesso: {usuario.dataUltimoAcesso ? dateTimeFormat(usuario.dataUltimoAcesso) : '--'}</p>
                <div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Salvar
                    </Button>
                </div>
                <div>
                    <Button
                        component={Link}
                        to={{
                            pathname: '/usuario-senha',
                            state: {
                                usuario: usuario
                            }
                        }}
                        type="button"
                        variant="contained"
                        color="secondary"
                        className={classes.submit}>
                        Trocar Senha
                    </Button>
                </div>
            </form>
        </Container>
    );
}

export default UsuarioForm;
import { Divider, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { dateFormat } from '../../../util/DateUtil';
import { currencyRound } from '../../../util/DoubleUtil';
import Button from '../../UI/Button';
import { consultarTransacao, criarTransacaoViaPix } from '../api-lancamento';
import { errorMessage } from '../../../util/ConfirmAlertUtil';

const LancamentoTableAlunoView = ({ lancamentos, setLancamentos }) => {
    const [isLoadingPixTransacao, setIsLoadingPixTransacao] = useState(false);

    const handleGerarPixQrCode = (lancamento) => {
        setIsLoadingPixTransacao(true);
        criarTransacaoViaPix(lancamento.codigo).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setLancamentos((prevState) => prevState.map((ps) => {
                        if (ps.codigo === lancamento.codigo) {
                            return data;
                        } else {
                            return ps;
                        }
                    }))
                }
            }
            setIsLoadingPixTransacao(false);
        })
    }

    const handleConsultarTransacao = (lancamento) => {
        setIsLoadingPixTransacao(true);
        consultarTransacao(lancamento.codigo).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setLancamentos((prevState) => ({
                        ...prevState,
                        pixTransacao: data,
                    }));
                }
            }
            setIsLoadingPixTransacao(false);
        });
    };

    return (
        <Grid style={{ marginTop: 8 }} container direction='column' spacing={3}>
            {lancamentos.length > 0 ?
                <Grid item xs={12} spacing={3}>
                    {lancamentos.map((lancamento) => (
                        <>
                            <Grid container direction='row' spacing={3}>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Ano Letivo: </span>{lancamento.anoLetivo}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Código Documento: </span>{lancamento.codDoc}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Data Emissão: </span>{dateFormat(lancamento.dataEmissao)}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Data Vencimento: </span>{dateFormat(lancamento.dataVencimento)}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Histórico: </span>{lancamento.historicoLancamento.descricao}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Valor: </span>{currencyRound(lancamento.valor)}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Data Desconto: </span>{lancamento.dataDesconto ? dateFormat(lancamento.dataDesconto) : '--'}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Valor Desconto: </span>{lancamento.valorDesconto ? currencyRound(lancamento.valorDesconto) : '--'}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Data Pagamento: </span>{lancamento.dataPagamento ? dateFormat(lancamento.dataPagamento) : '--'}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Valor Pago: </span>{lancamento.valorPago ? currencyRound(lancamento.valorPago) : '--'}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Código de Barras: </span>{lancamento.codigoBarras ? lancamento.codigoBarras : '--'}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Forma Pago: </span>{lancamento.formaPago ? lancamento.formaPago : '--'}</Typography>
                                </Grid>
                                <Grid item lg={4} md={6} xs={12}>
                                    <Typography variant='body1'><span style={{ fontWeight: 'bold' }}>Quitado: </span>{lancamento.quitado ? 'Sim' : 'Não'}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                {!lancamento.pixTransacao || lancamento.pixTransacao.quitado === null ||
                                    lancamento.pixTransacao.quitado === false ?
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3} direction="row">
                                                <Grid item>
                                                    <Button
                                                        disabled={isLoadingPixTransacao}
                                                        onClick={() => handleGerarPixQrCode(lancamento)}
                                                    >
                                                        Gerar Pix Qr Code
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        disabled={isLoadingPixTransacao}
                                                        onClick={() => handleConsultarTransacao(lancamento)}
                                                    >
                                                        Consultar Transação
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {lancamento.pixTransacao && (
                                            <Grid item>
                                                <Typography variant='h6'>Pix</Typography>
                                                <Typography>
                                                    {lancamento.pixTransacao &&
                                                        lancamento.pixTransacao.qrCodeTexto
                                                        ? lancamento.pixTransacao.qrCodeTexto
                                                        : ""}
                                                </Typography>
                                                <img
                                                    alt=""
                                                    src={
                                                        lancamento.pixTransacao &&
                                                            lancamento.pixTransacao.qrCodeImagem
                                                            ? lancamento.pixTransacao.qrCodeImagem
                                                            : null
                                                    }
                                                />
                                            </Grid>
                                        )}
                                    </> : <></>}



                            </Grid>
                        </>

                    ))}
                </Grid>
                : ''
            }

        </Grid>
    );
}

export default LancamentoTableAlunoView;
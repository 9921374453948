import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import UsuarioForm from './UsuarioForm';
import UsuarioPerfilTableAdd from './UsuarioPerfilTableAdd';
import AlunoResponsavelTableAdd from "../AlunoResponsavel/AlunoResponsavelTableAdd";
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import { ALUNO } from '../Usuario/Perfil';
import UsuarioFindDialog from './UsuarioFindDialog';
import { page, remove, save } from './api-usuario';
import { errorMessage } from '../../util/ConfirmAlertUtil';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import UsuarioList from './UsuarioList';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px',
    },
    cursorLink: {
        cursor: 'pointer'
    },
    voltarButton: {
        marginBottom: '20px',
    },
    closeIcon: {
        marginBottom: '20px',
        cursor: 'pointer'
    },
});

const UsuarioContainer = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const [openSearchUsuario, setOpenSearchUsuario] = useState(false);
    const [usuarios, setUsuarios] = useState({
        content: [],
        totalElements: 0,
        number: 0,
        numberOfElements: 0
    });
    const [usuario, setUsuario] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        page(0).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setUsuarios(data);
            }
            setIsLoading(false);
        });
    }, []);

    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        page(newPage).then((data) => {
            if (data.error) {
                errorMessage(data.message);
            } else {
                setUsuarios(data);
            }
            setIsLoading(false);
        });
    };

    const handleDeleteNoAuthorized = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Usuário com perfil sem autorização para Remover.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }
    const handleUserRegisteredTdp = () => {
        confirmAlert({
            title: 'Alerta',
            message: 'Usuário com registro em turma disciplina. Remova todas Turmas Disciplinas para remover o usuário.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleDelete = (index) => {
        confirmAlert({
            title: 'Alerta',
            message: 'Deseja deletar este usuário?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        remove(usuarios.content[index].id).then((data) => {
                            console.log(data);
                            if (data.error) {
                                if (data.message === 'User not Authorized.') {
                                    handleDeleteNoAuthorized();
                                } else if (data.message === 'User registered in TDP') {
                                    handleUserRegisteredTdp();
                                }

                            } else {
                                const content = usuarios.content;
                                content.splice(index, 1);
                                setUsuarios({
                                    ...usuarios,
                                    content: content
                                });
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProcurar = () => {
        handleMenuClose();
        setOpenSearchUsuario(true);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleGoBack = () => {
        history.push('/home')
    }

    const handleSelectedUsuario = (u) => {
        setUsuario(u);
        setOpenSearchUsuario(false);
    }

    const closeUsuarioSearch = () => {
        setOpenSearchUsuario(false);
    }

    const handleSaveUsuario = (event) => {
        event.preventDefault();
        const eNovo = usuario.eNovo;

        if (!usuario.nome) {
            toast.error("Preencha o Nome.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        if (!usuario.email) {
            toast.error("Preencha o E-mail.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        if (!usuario.perfil) {
            toast.error("Selecione o Perfil.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }

        save(usuario).then(data => {
            if (data) {
                if (data.error) {
                    if (data.message === 'User not authorized') {
                        toast.error("Este usuário não possui perfil para alteração.", {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    } else if (data.message === 'Email already exists') {
                        toast.error("Email já cadastrado para outro usuário.", {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    } else {
                        errorMessage(data.message);
                    }
                } else {
                    toast.success("Salvo com sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    if (eNovo) {
                        setUsuarios((prevState) => ({
                            ...prevState,
                            content: [
                                data,
                                ...prevState.content
                            ]
                        }))
                    } else {
                        setUsuarios((prevState) => ({
                            ...prevState,
                            content: prevState.content.map((content) => {
                                if (content.id === data.id) {
                                    return data;
                                } else {
                                    return content;
                                }
                            })
                        }));
                    }
                    setUsuario(data)
                }
            }
        });
    }


    return (
        <div>
            <Container
                className={classes.paper}
                component={Paper}
            >
                <UsuarioFindDialog
                    openSearchUsuario={openSearchUsuario}
                    handleSelectedUsuario={handleSelectedUsuario}
                    closeUsuarioSearch={closeUsuarioSearch}
                />
                <Button
                    onClick={handleGoBack}
                    variant="contained"
                    color="secondary"
                    className={classes.voltarButton}
                >
                    <ArrowBackIcon style={{ marginRight: '10px' }} />
                    Voltar
                </Button>
                <Grid container className={classes.title} justifyContent="space-between">
                    <Typography variant="h6">
                        Usuários
                    </Typography>

                    <MenuIcon
                        className={classes.cursorLink}
                        onClick={(event) => handleMenuClick(event)}
                        color="secondary"
                    />
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem component={Link}
                            to={{ pathname: '/usuario-import' }}>
                            Importar CSV
                        </MenuItem>
                        <MenuItem onClick={() => handleProcurar()}>
                            Procurar Usuário
                        </MenuItem>


                    </Menu>

                </Grid>
                {usuario ?
                    <>
                        <Container className={classes.paper} component={Paper}>
                            <CloseIcon
                                onClick={() => setUsuario(null)}
                                className={classes.closeIcon}
                                fontSize='small'
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <UsuarioForm
                                        usuario={usuario}
                                        setUsuario={setUsuario}
                                        handleSaveUsuario={handleSaveUsuario}
                                    />
                                </Grid>
                                {usuario.eNovo ? '' :
                                    <>
                                        <Grid item xs={4}>
                                            <UsuarioPerfilTableAdd
                                                usuario={usuario}
                                            />
                                        </Grid>
                                        {usuario.perfil !== ALUNO &&
                                            <Grid item xs={12}>
                                                <AlunoResponsavelTableAdd
                                                    usuario={usuario}
                                                    setUsuario={setUsuario}
                                                />
                                            </Grid>
                                        }


                                    </>
                                }

                            </Grid>

                        </Container>
                    </> :
                    <UsuarioList
                        usuarios={usuarios}
                        isLoading={isLoading}
                        handleChangePage={handleChangePage}
                        handleDelete={handleDelete}
                        setUsuario={setUsuario}
                    />
                }
            </Container>
        </div>
    );
}

export default UsuarioContainer;
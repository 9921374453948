import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { save } from './api-atividade';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { addAtividades, updateAtividades } from './actions/actions';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { setAtividade as setAtividadeRedux } from './actions/actions';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { DateTimePicker } from '@material-ui/pickers';
import { dateFormatMoment } from '../../util/DateUtil';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import SunEditor, { buttonList } from 'suneditor-react';
import { ptBR } from "date-fns/locale";
import { errorMessage } from '../../util/ConfirmAlertUtil';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: "20px"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
        paddingTop: '20px;'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    closeIcon: {
        marginTop: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    },
    loading: {
        marginRight: '10px',
        marginLeft: '10px'
    }
}));

export default function AtividadeForm() {
    const classes = useStyles();
    const atividadeRedux = useSelector(state => state.atividade);
    const tdp = useSelector(state => state.atividade);
    const [atividade, setAtividade] = useState({
        tdp: tdp
    });
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    useEffect(() => {
        setAtividade(atividadeRedux);
    }, [atividadeRedux])

    const dispatch = useDispatch();

    const handleChange = (event) => {
        setAtividade({
            ...atividade,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeConteudo = (value) => {
        setAtividade((prevState) => ({
            ...prevState,
            conteudo: value.replace(/[\u200B-\u200D\uFEFF]/g, '')
        }))
    }

    const handleDataChange = (data) => {
        if (data)
            data.setHours(0, 0, 0, 0);

        setAtividade({
            ...atividade,
            data: data
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setIsLoadingSave(true);
        const codAtividade = atividade.codigo;
        save(atividade).then(data => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    toast.success("Salvo com sucesso.", {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                    if (codAtividade) {
                        dispatch(updateAtividades(data));
                    } else {
                        dispatch(addAtividades(data));
                    }
                    dispatch(setAtividadeRedux(null));
                }
            }
            setIsLoadingSave(false);

        }).catch(err => {
            console.log(err);
        })

    }

    const handleSwitchChange = name => event => {
        setAtividade((prevState) => ({
            ...prevState,
            [name]: event.target.checked
        }));
    };


    const handleDataLimiteEntregaChange = (dataLimiteEntrega) => {

        setAtividade({
            ...atividade,
            dataLimiteEntrega: dataLimiteEntrega
        })
    }


    return (
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <Container className={classes.paper} component={Paper}>
                <Grid container justify="space-between">
                    <Grid item xs={3}>
                        <CloseIcon
                            onClick={() => dispatch(setAtividadeRedux(null))}
                            className={classes.closeIcon}
                            fontSize='small'
                        />
                    </Grid>
                </Grid>
                <form onSubmit={clickSubmit}>
                    <div>
                        <KeyboardDatePicker
                            required
                            size='small'
                            variant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            label="Data"
                            value={atividade && atividade.data ? dateFormatMoment(atividade.data) : null}
                            onChange={handleDataChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <TextField
                            size='small'
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            inputProps={{ maxLength: 100 }}
                            label="Descrição"
                            name="descricao"
                            onChange={handleChange}
                            value={atividade && atividade.descricao ? atividade.descricao : ''}
                        />
                        <SunEditor
                            onChange={handleChangeConteudo}
                            setContents={atividade && atividade.conteudo ? atividade.conteudo : ''}
                            setOptions={{
                                height: 500,
                                buttonList: buttonList.complex
                            }}
                        />
                        {/* <TextField
                            variant="outlined"
                            size='small'
                            margin="normal"
                            multiline
                            required
                            rows="4"
                            inputProps={{ maxLength: 800 }}
                            fullWidth
                            label="Conteúdo"
                            name="conteudo"
                            onChange={handleChange}
                            value={atividade && atividade.conteudo ? atividade.conteudo : ''}
                        /> */}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={atividade && atividade.semNota ? true : false}
                                    onChange={handleSwitchChange('semNota')}
                                    value={atividade && atividade.semNota ? true : false}
                                />
                            }
                            label="Sem nota"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={atividade && atividade.entrega ? true : false}
                                    onChange={handleSwitchChange('entrega')}
                                    value={atividade && atividade.entrega ? true : false}
                                />
                            }
                            label="Entrega"
                        />
                        <div>
                            {atividade && atividade.entrega ?
                                <DateTimePicker
                                    size='small'
                                    ampm={false}
                                    required
                                    variant="outlined"
                                    margin="normal"
                                    label="Data Limite Entrega"
                                    value={atividade && atividade.dataLimiteEntrega ? atividade.dataLimiteEntrega : null}
                                    onChange={handleDataLimiteEntregaChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                                : ''}
                        </div>
                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoadingSave}
                        color="primary"
                        className={classes.submit}>
                        {isLoadingSave && <CircularProgress className={classes.loading} color="primary" size={20} />}
                        Salvar
                    </Button>
                </form>
            </Container>


        </MuiPickersUtilsProvider>
    );
}
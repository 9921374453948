import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ADMIN, COORDENADOR, PROFESSOR } from '../../Usuario/Perfil';
import { useSelector } from 'react-redux';
import { setIsAll, setSalas } from './actions/actions';
import { useDispatch } from 'react-redux';
import MensagemListUser from './MensagemListUser';
import MensagemListAll from './MensagemListAll';

const useStyles = makeStyles({

    title: {
        margin: '20px'
    },
    novoRegistro: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    menuIcon: {
        cursor: 'pointer',
        margin: '20px'
    },
});


export default function MensagemTable() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState();
    const user = useSelector(state => state.usuario);
    const isAll = useSelector(state => state.isAllMensagemTable);
    const dispatch = useDispatch();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMostrarTodas = event => {
        dispatch(setIsAll(event.target.checked));
        if (!event.target.checked) {
            dispatch(setSalas(null));
        }
    };

    return (
        <Container component={Paper}>
            <Grid container justifyContent="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h6">
                        Mensagens
                    </Typography>
                </Grid>
                <Grid style={{ flexBasis: '5%' }} item xs={3}>

                    <MenuIcon
                        fontSize='small'
                        className={classes.menuIcon}
                        onClick={(event) => handleMenuClick(event)}
                        color="secondary"

                    />
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {
                            user && user.perfil && (user.perfil === ADMIN ||
                                user.perfil === COORDENADOR ||
                                user.perfil === PROFESSOR)
                                ?

                                <MenuItem
                                    component={Link}
                                    to={{
                                        pathname: '/group-form',

                                    }}
                                >
                                    Criar Grupo
                                </MenuItem>
                                : ''
                        }



                        <MenuItem
                            component={Link}
                            to={{
                                pathname: isAll ? '/mensagem-find-all' : '/mensagem-find',
                                state: {
                                    user: user
                                }
                            }}
                        >
                            Procurar
                        </MenuItem>
                    </Menu>

                </Grid>
            </Grid>

            {user && (user.perfil === ADMIN || user.perfil === COORDENADOR) ?
                <FormControlLabel
                    control={
                        <Switch
                            checked={isAll}
                            onChange={handleMostrarTodas}
                            value={isAll ? isAll : ''}
                        />
                    }
                    style={{ marginBottom: '20px' }}
                    label="Mostrar Todas as Mensagens"
                /> :
                ''}

            {isAll && (user.perfil === ADMIN || user.perfil === COORDENADOR) ? <MensagemListAll /> :
                <MensagemListUser />
            }


        </Container>
    );
}
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import ButtonImageLabel from '../../UI/ButtonImageLabel';
import ParcelaPendenteIcon from '../../../shared/assets/img/financeiro/pendente.png';
import ParcelaVencerIcon from '../../../shared/assets/img/financeiro/parcela_a_vencer.png';
import HistoricoIcon from '../../../shared/assets/img/financeiro/historico.png';

const useStyles = makeStyles({
    divMenu: {
        display: 'flex',
        flexDirection: 'row',
        margin: '40px'
    },
    divButton: {
        width: '50%'
    },

})

const FinanceiroMenuV2 = ({
    handleParcelaPendente,
    handleParcelaVencer,
    handleHistorico,
}) => {
    const classes = useStyles();
    return (
        <Grid container direction='row' spacing={3} >
            <Grid item xs={12} md={4} xl={2} justifyContent='center' >
                <ButtonImageLabel
                    description='Parcela(s) Pendente(s)'
                    image={ParcelaPendenteIcon}
                    onClick={handleParcelaPendente}
                />
            </Grid>
            <Grid item xs={12} md={4} xl={2} justifyContent='center'>
                <ButtonImageLabel
                    description='Parcela(s) a Vencer'
                    image={ParcelaVencerIcon}
                    onClick={handleParcelaVencer}
                />
            </Grid>
            <Grid item xs={12} md={4} xl={2} justifyContent='center'>
                <ButtonImageLabel
                    description='Histórico'
                    image={HistoricoIcon}
                    onClick={handleHistorico}
                />
            </Grid>
        </Grid>
    )
}

export default FinanceiroMenuV2;
import { SERVER_URL } from '../constants.js';

const signin = (user) => {
  return fetch(SERVER_URL + 'auth/signin', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'no-cors',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'
    },
    // credentials: 'include',
    body: JSON.stringify(user)
  })
    .then((response) => {
      return response;
    });
}

const requestResetPassword = (email) => {
  return fetch(SERVER_URL + 'reset-password/request', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'no-cors'
    },
    body: email
  })
    .then((response) => {
      return response.json();
    });
}

const changePassword = (usuarioPassword) => {
  return fetch(SERVER_URL + 'reset-password/change-password', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'no-cors'
    },
    body: JSON.stringify(usuarioPassword)
  })
    .then((response) => {
      return response.json();
    });
}


export {
  signin,
  requestResetPassword,
  changePassword
}
